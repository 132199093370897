import { createRouter, createWebHashHistory } from "vue-router";
import IntegrationRewardSupplierList from "./IntegrationRewardSupplierList.vue";
import IntegrationRewardSupplierDetail from "./IntegrationRewardSupplierDetail.vue";
const routes = [
    {
        path: "/",
        component: IntegrationRewardSupplierList,
        props: true,
    },
    {
        path: "/:rewardSupplierId",
        component: IntegrationRewardSupplierDetail,
        props: true,
    },
];
const router = createRouter({
    history: createWebHashHistory(),
    linkActiveClass: "active",
    routes,
});
export default router;
