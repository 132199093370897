import { BuiltCampaignControllerAction } from "./BuiltCampaignControllerAction";
export const isBuiltCampaignControllerActionWebhook = (action) => {
    return action.action_type === "WEBHOOK";
};
export class BuiltCampaignControllerActionWebhook extends BuiltCampaignControllerAction {
    constructor(response, context) {
        super(response, context);
        this.action_id = response.action_id;
        this.quality = response.quality;
        this.enabled = response.enabled;
        this.component_ids = response.component_ids;
        this.webhook_id = response.webhook_id;
        this.data = response.data;
    }
}
