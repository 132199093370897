var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { computed } from "vue";
import { useCampaignComponentVariableStore } from ".";
import { useAsyncPrimer } from "../../common/async-primer";
export const useCampaignComponentVariables = (campaignId, componentId) => {
    const campaignComponentVariableStore = useCampaignComponentVariableStore();
    const primer = useAsyncPrimer(() => __awaiter(void 0, void 0, void 0, function* () {
        yield campaignComponentVariableStore.fetchVariables(campaignId, componentId);
    }), `useCampaignComponentVariables-${campaignId}-${componentId}`);
    const campaignComponentVariables = computed(() => {
        return campaignComponentVariableStore.getVariables(campaignId, componentId);
    });
    return {
        primer,
        campaignComponentVariables,
    };
};
export const useCampaignComponentVariable = (campaignId, componentId, name) => {
    const campaignComponentVariableStore = useCampaignComponentVariableStore();
    const { primer } = useCampaignComponentVariables(campaignId, componentId);
    const campaignComponentVariable = computed(() => {
        return campaignComponentVariableStore.getVariable(campaignId, componentId, name);
    });
    return {
        primer,
        campaignComponentVariable,
    };
};
