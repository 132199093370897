import { CampaignFrontendAsset } from "./CampaignFrontendAsset";
const getJourneyName = (step) => {
    return step.journey_names[0];
};
const getCreativeClassification = (creativeAction) => {
    return creativeAction.classification;
};
export const isCreativeActionCampaignFrontendAsset = (asset) => {
    return asset.type === "CREATIVE_ACTION";
};
export const getTrigger = (step) => {
    const firstMatchingEventTrigger = step.triggers.find((trigger) => {
        return (trigger.trigger_type === "EVENT" &&
            trigger.trigger_phase === "MATCHING");
    });
    if (!firstMatchingEventTrigger) {
        return undefined;
    }
    return firstMatchingEventTrigger;
};
export class CreativeActionCampaignFrontendAsset extends CampaignFrontendAsset {
    constructor(parameters) {
        super({
            type: "CREATIVE_ACTION",
            step: parameters.step,
            trigger: parameters.trigger,
            component: parameters.component,
            isLegacyCampaign: parameters.isLegacyCampaign,
        });
        this.action = parameters.action;
    }
    getActionId() {
        return this.action.action_id;
    }
    getAssetHandle() {
        return this.getActionId();
    }
    getPreview() {
        const inputEventName = this.getInputEventName();
        const creativeClassification = getCreativeClassification(this.action);
        const journeyName = getJourneyName(this.step);
        if (!inputEventName || !journeyName) {
            return null;
        }
        return {
            campaignId: this.getCampaignId(),
            campaignVersion: this.getCampaignVersion(),
            zoneName: inputEventName,
            creativeClassification,
            journeyName,
        };
    }
    getCreativeDownloadUrl() {
        return this.action.getDownloadUrl();
    }
    getClassification() {
        return this.action.classification;
    }
    getApiVersion() {
        return this.action.api_version;
    }
    isLegacy() {
        return this.action.api_version < 8;
    }
}
