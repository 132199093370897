import { computed } from "vue";
import { useBuiltComponent } from "./useBuiltComponent";
import { filterComponentVariables } from "../../component/composables/useComponentVariables";
export const useBuiltComponentVariables = (componentId) => {
    const { builtComponent } = useBuiltComponent(componentId);
    const builtComponentVariables = computed(() => {
        var _a;
        return filterComponentVariables(((_a = builtComponent.value) === null || _a === void 0 ? void 0 : _a.listVariables()) || []);
    });
    return {
        builtComponentVariables,
    };
};
