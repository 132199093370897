import { BuiltCampaignControllerAction } from "./BuiltCampaignControllerAction";
export const isBuiltCampaignControllerActionIncentivize = (action) => {
    return action.action_type === "INCENTIVIZE";
};
export class BuiltCampaignControllerActionIncentivize extends BuiltCampaignControllerAction {
    constructor(response, context) {
        super(response, context);
        this.action_name = response.action_name;
        this.incentivize_action_type = response.incentivize_action_type;
    }
}
