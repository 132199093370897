import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Highcharts from "highcharts";
import rewardStateSummaryService from "src/account-rewards/reward-state-summary-service";
import NotificationFlashView from "src/shared/notification/NotificationFlashView";
import ChartColors from "src/shared/charts/ChartColors";
import Numbers from "src/shared/Numbers";

function drawChart(el, series) {
    Highcharts.chart(el, {
        credits: {
            enabled: false,
        },
        chart: {
            backgroundColor: "transparent",
            type: "bar",
        },

        title: {
            text: "",
        },

        legend: {
            enabled: false,
        },

        plotOptions: {
            bar: {
                stacking: "normal",
            },
        },

        xAxis: {
            categories: ["Reward Distribution"],
            lineWidth: 0,
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            labels: {
                enabled: false,
            },
            minorTickLength: 0,
            tickLength: 0,
        },

        yAxis: {
            reversed: true,
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            labels: {
                enabled: false,
            },
            title: {
                text: null,
            },
        },

        tooltip: {
            shared: false,
            useHTML: true,
            formatter() {
                return `<span class='chart-tooltip-point' style='background: ${
                    this.color
                };'></span>${this.series.name}: <strong>${Numbers.humanize(
                    this.y
                )}</strong>`;
            },
        },

        series,
    });
}

function create($root, rewardSupplierId) {
    const loaderView = NotificationFlashView.createInside(
        $root,
        "Loading",
        $(),
        true
    );
    rewardStateSummaryService
        .getStateSummaryAllTime(rewardSupplierId)
        .done((stateSummaryOutput) => {
            loaderView.hide();
            let hasData = false;
            _.each(stateSummaryOutput, (value) => {
                if (value !== 0) {
                    hasData = true;
                }
            });
            if (!hasData) {
                $root.parent(".chart").addClass("no-data");
                return;
            }
            const chartSeries = [
                {
                    name: "Failed",
                    color: ChartColors.RED,
                    data: [stateSummaryOutput.failed],
                },
                {
                    name: "Canceled",
                    color: ChartColors.LIGHT_GRAY,
                    data: [
                        stateSummaryOutput.canceled +
                            stateSummaryOutput.revoked,
                    ],
                },
                {
                    name: "Earned",
                    color: ChartColors.MEDIUM_GRAY,
                    data: [stateSummaryOutput.earned],
                },
                {
                    name: "Fulfilled",
                    color: ChartColors.BLUE,
                    data: [
                        stateSummaryOutput.fulfilled + stateSummaryOutput.sent,
                    ],
                },
                {
                    name: "Redeemed",
                    color: ChartColors.GREEN,
                    data: [stateSummaryOutput.redeemed],
                },
            ];
            drawChart($root[0], chartSeries);
        });
}

export default {
    create,
};
