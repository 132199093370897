var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useComponentVariableStateStore, useComponentMutableStateStore, isVariablesBuildFailedError, } from "src/shared/v-components/component-variable/item/component-variable-state";
import { useComponentStoreV2 } from "..";
const clearErrors = (componentVariableMutables) => {
    const componentVariableStateStore = useComponentVariableStateStore();
    componentVariableMutables.forEach((variable) => {
        componentVariableStateStore.clearError(variable.getComponentMutableKey(), variable.name);
    });
};
const startLoading = (componentVariableMutables) => {
    const componentVariableStateStore = useComponentVariableStateStore();
    componentVariableMutables.forEach((variable) => {
        componentVariableStateStore.startLoading(variable.getComponentMutableKey(), variable.name);
    });
};
const stopLoading = (componentVariableMutables) => {
    const componentVariableStateStore = useComponentVariableStateStore();
    componentVariableMutables.forEach((variable) => {
        componentVariableStateStore.stopLoading(variable.getComponentMutableKey(), variable.name);
    });
};
export const saveComponent = (componentMutable) => __awaiter(void 0, void 0, void 0, function* () {
    const componentStore = useComponentStoreV2();
    const componentVariableStateStore = useComponentVariableStateStore();
    const componentMutableStateStore = useComponentMutableStateStore();
    const dirtyComponentVariables = componentMutable.getDirtyVariables();
    try {
        clearErrors(dirtyComponentVariables);
        startLoading(dirtyComponentVariables);
        componentMutableStateStore.startLoading(componentMutable.componentMutableKey);
        if (componentMutable.areAttributesDirty()) {
            yield componentStore.updateComponent(componentMutable.campaign_id, componentMutable.component_id, componentMutable);
        }
        if (componentMutable.areVariablesDirty()) {
            yield componentStore.batchUpdateComponentVariables(componentMutable.component_id, dirtyComponentVariables);
        }
    }
    catch (error) {
        if (isVariablesBuildFailedError(error)) {
            componentVariableStateStore.registerVariablesBuildFailedError(componentMutable.componentMutableKey, error);
        }
    }
    finally {
        stopLoading(dirtyComponentVariables);
        componentMutableStateStore.stopLoading(componentMutable.componentMutableKey);
    }
});
export const saveComponentVariable = (componentVariableMutable) => __awaiter(void 0, void 0, void 0, function* () {
    const componentStore = useComponentStoreV2();
    const componentVariableStateStore = useComponentVariableStateStore();
    const componentMutableStateStore = useComponentMutableStateStore();
    try {
        clearErrors([componentVariableMutable]);
        startLoading([componentVariableMutable]);
        componentMutableStateStore.startLoading(componentVariableMutable.getComponentMutableKey());
        yield componentStore.batchUpdateComponentVariables(componentVariableMutable.getComponentId(), [componentVariableMutable]);
    }
    catch (error) {
        if (isVariablesBuildFailedError(error)) {
            componentVariableStateStore.registerVariablesBuildFailedError(componentVariableMutable.getComponentMutableKey(), error);
        }
    }
    finally {
        stopLoading([componentVariableMutable]);
        componentMutableStateStore.stopLoading(componentVariableMutable.getComponentMutableKey());
    }
});
