import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import rewardSupplierService from "src/account-rewards/reward-supplier-campaign-usage/reward-supplier-service";
import NotificationEllipsisView from "src/shared/notification/NotificationEllipsisView";
import NotificationFlashView from "src/shared/notification/NotificationFlashView";
import DateTimeParseService from "src/shared/date-time-parse-service";
import Strings from "src/shared/Strings";
import template from "src/account-rewards/reward-supplier-campaign-usage/template.html?raw";

const RewardSupplierCampaignUsageView = Backbone.View.extend({
    template: _.template(template),

    initialize(parameters) {
        this.campaigns = parameters.campaigns;
    },

    render() {
        this.$el.hide();
        this.$el.html(
            this.template({
                campaigns: this.campaigns.toJSON(),
                formatState,
                formatStateClass,
                formatDateTime,
            })
        );
        this.$el.fadeIn();
    },
});

RewardSupplierCampaignUsageView.showRewardSupplierUsageText = function (
    $root,
    rewardSupplierId
) {
    NotificationEllipsisView.create($root);
    rewardSupplierService
        .loadAllCampaignsBySupplier(rewardSupplierId)
        .done((campaigns) => {
            const campaignCount = campaigns.length;
            const usedInCampaignsMessage =
                campaignCount === 0
                    ? "Not used"
                    : `Used in ${campaignCount} campaign${
                          campaignCount === 1 ? "" : "s"
                      }`;
            $root.text(usedInCampaignsMessage);
        })
        .fail(() => {
            $root.text("--");
        });
};

RewardSupplierCampaignUsageView.showRewardSupplierUsage = function (
    $root,
    rewardSupplierId
) {
    NotificationFlashView.createInside($root);
    rewardSupplierService
        .loadAllCampaignsBySupplier(rewardSupplierId)
        .done((campaigns) => {
            new RewardSupplierCampaignUsageView({
                el: $root,
                campaigns,
            }).render();
        });
};

RewardSupplierCampaignUsageView.showRewardSupplierUsageByType = function (
    $root,
    rewardType
) {
    NotificationFlashView.createInside($root);
    rewardSupplierService
        .loadCampaignsBySupplierType(rewardType)
        .done((campaigns) => {
            new RewardSupplierCampaignUsageView({
                el: $root,
                campaigns,
            }).render();
        });
};

function formatState(state) {
    return Strings.capitalize(state);
}

function formatStateClass(state) {
    return state.replace("_", "-");
}

function formatDateTime(timestamp) {
    return DateTimeParseService.parse(timestamp);
}

export default RewardSupplierCampaignUsageView;
