import { computed } from "vue";
import { values } from "lodash";
export const EVALUATABLE_PREFIX_PATTERN = /^(spel|javascript|handlebars)@(buildtime|runtime)\:/;
const handlebarsBuildtimeSyntaxRegex = /\{\{.*?\}\}/;
const doesEvaluatableValueHaveHandlebarsSyntax = (value) => {
    return handlebarsBuildtimeSyntaxRegex.test(value);
};
export const cleanEvaluatablePrefix = (value) => {
    return value.replace(EVALUATABLE_PREFIX_PATTERN, "");
};
export const isEvaluatable = (value) => {
    if (typeof value !== "string") {
        return false;
    }
    return EVALUATABLE_PREFIX_PATTERN.test(value);
};
const evaluatablePhaseOptionDefinitions = {
    buildtime: {
        displayName: "Buildtime",
        value: "buildtime",
    },
    runtime: {
        displayName: "Runtime",
        value: "runtime",
    },
};
const evaluatableLanguageOptionDefinitions = {
    javascript: {
        displayName: "JavaScript",
        value: "javascript",
    },
    spel: {
        displayName: "SpEL",
        value: "spel",
    },
    handlebars: {
        displayName: "Handlebars",
        value: "handlebars",
    },
};
export const evaluatablePhaseOptions = values(evaluatablePhaseOptionDefinitions);
export const evaluatableLanguageOptions = values(evaluatableLanguageOptionDefinitions);
export const destructureEvaluatableValue = (value) => {
    if (typeof value !== "string") {
        return {
            value,
            prefix: null,
            language: null,
            phase: null,
            isProvided: true,
        };
    }
    else {
        const result = value.match(EVALUATABLE_PREFIX_PATTERN);
        if (result === null) {
            return {
                value,
                prefix: null,
                language: null,
                phase: null,
                isProvided: true,
            };
        }
        else {
            const [prefix, language, phase] = result;
            return {
                prefix,
                language: language,
                phase: phase,
                value: value.replace(EVALUATABLE_PREFIX_PATTERN, ""),
                isProvided: false,
            };
        }
    }
};
const restructureProvidedValue = (value) => {
    if (typeof value === "string") {
        if (doesEvaluatableValueHaveHandlebarsSyntax(value)) {
            return `handlebars@buildtime:${value}`;
        }
    }
    return value;
};
export const restructureDestructuredEvaluatableValue = (destructuredEvaluatableValue) => {
    const { value, language, phase } = destructuredEvaluatableValue;
    if (typeof value !== "string" ||
        (language === "handlebars" &&
            phase === "buildtime" &&
            !doesEvaluatableValueHaveHandlebarsSyntax(value))) {
        return value;
    }
    return `${language}@${phase}:${value}`;
};
const restructureEvaluatableValue = (destructuredEvaluatableValue) => {
    if (destructuredEvaluatableValue.isProvided) {
        return restructureProvidedValue(destructuredEvaluatableValue.value);
    }
    else {
        return restructureDestructuredEvaluatableValue(destructuredEvaluatableValue);
    }
};
export const useDestructuredEvaluatableValue = (rawValue) => {
    const destructuredEvaluatableValue = computed(() => {
        return destructureEvaluatableValue(rawValue.value);
    });
    const value = computed({
        get() {
            return destructuredEvaluatableValue.value.value;
        },
        set(newValue) {
            rawValue.value = restructureEvaluatableValue(Object.assign(Object.assign({}, destructuredEvaluatableValue.value), { value: newValue }));
        },
    });
    const language = computed(() => {
        return destructuredEvaluatableValue.value.language;
    });
    const phase = computed(() => {
        return destructuredEvaluatableValue.value.phase;
    });
    const isProvided = computed(() => {
        return destructuredEvaluatableValue.value.isProvided;
    });
    const isEvaluatablePrivate = computed(() => {
        return !isProvided.value;
    });
    const setPhase = (phase) => {
        rawValue.value = restructureEvaluatableValue(Object.assign(Object.assign({}, destructuredEvaluatableValue.value), { phase }));
    };
    const setLanguage = (language) => {
        rawValue.value = restructureEvaluatableValue(Object.assign(Object.assign({}, destructuredEvaluatableValue.value), { language }));
    };
    const makeProvided = (value) => {
        rawValue.value = value;
    };
    const makeEvaluatable = (language, phase) => {
        rawValue.value = `${language}@${phase}:${destructuredEvaluatableValue.value.value}`;
    };
    return {
        value,
        language,
        phase,
        isProvided,
        isEvaluatable: isEvaluatablePrivate,
        setPhase,
        setLanguage,
        makeProvided,
        makeEvaluatable,
    };
};
