export default {
    capitalize(string) {
        if (!string) {
            return "";
        }

        return string
            .toLowerCase()
            .split(/[\s_-]+/)
            .map((word) => {
                if (word.length === 0) {
                    return "";
                }

                return word[0].toUpperCase() + word.slice(1);
            })
            .join(" ");
    },
};
