var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { chain, forEach } from "lodash";
import { memoize } from "underscore";
import { computed, ref, toValue, watch } from "vue";
import { useComponentGraphStore } from ".";
import { useBuiltComponentStore } from "../component-built";
import { useBuiltComponent, useBuiltComponents, } from "../component-built/composables";
import { globalComponentGraphVersion } from "./global-component-graph-version";
const useChildComponentIds = (componentId, degree = ref(null)) => {
    const componentGraphStore = useComponentGraphStore();
    return computed(() => {
        const componentIdValue = toValue(componentId);
        if (!componentIdValue) {
            return [];
        }
        return componentGraphStore.getChildComponentIds(componentIdValue, degree.value);
    });
};
export const useParentComponentIds = (componentId) => {
    const componentGraphStore = useComponentGraphStore();
    return computed(() => {
        const componentIdValue = toValue(componentId);
        if (!componentIdValue) {
            return [];
        }
        return componentGraphStore.getParentComponentIds(componentIdValue);
    });
};
const loadBuiltComponent = memoize((componentId, builtComponentStore) => __awaiter(void 0, void 0, void 0, function* () {
    yield builtComponentStore.fetchBuiltComponent(componentId);
}));
export const useParentBuiltComponents = (componentId) => {
    const builtComponentStore = useBuiltComponentStore();
    const parentComponentIds = useParentComponentIds(componentId);
    const parentComponents = computed(() => {
        return chain(parentComponentIds.value)
            .map((parentComponentId) => {
            const component = builtComponentStore.getBuiltComponent(parentComponentId);
            if (component) {
                return component;
            }
            else {
                loadBuiltComponent(parentComponentId, builtComponentStore);
                return null;
            }
        })
            .compact()
            .value();
    });
    return parentComponents;
};
export const useParentBuiltCampaignComponentVariable = (componentId, variableName) => {
    const parentComponents = useParentBuiltComponents(componentId);
    const parentComponentVariable = computed(() => {
        for (const parentComponent of parentComponents.value) {
            const variable = parentComponent.findVariable(variableName.value);
            if (variable &&
                variable.source_component_id === variable.context.componentId) {
                return variable;
            }
        }
        return undefined;
    });
    return parentComponentVariable;
};
export const useComponentVariableNodeType = (componentId, variableName) => {
    const parentComponentVariable = useParentBuiltCampaignComponentVariable(componentId, variableName);
    const impactedComponentIds = useImpactedComponentIds(componentId, variableName);
    const nodeType = computed(() => {
        const hasParentToInheritFrom = computed(() => {
            return parentComponentVariable.value !== undefined;
        });
        const hasChildrenToOverride = computed(() => {
            return impactedComponentIds.value.size > 0;
        });
        if (hasParentToInheritFrom.value) {
            if (hasChildrenToOverride.value) {
                return "MEDIATOR";
            }
            else {
                return "INSTANCE";
            }
        }
        else {
            if (hasChildrenToOverride.value) {
                return "ASSET";
            }
            else {
                return "INDEPENDENT";
            }
        }
    });
    return {
        nodeType,
        parentComponentVariable,
    };
};
const calculateImpactedComponentIds = memoize((componentId, variableName, componentGraphVersion) => {
    var _a, _b;
    const componentGraphStore = useComponentGraphStore();
    const builtComponentStore = useBuiltComponentStore();
    const childComponentIds = componentGraphStore.getChildComponentIds(componentId);
    const sourceComponentId = (_b = (_a = builtComponentStore
        .getBuiltComponent(componentId)) === null || _a === void 0 ? void 0 : _a.findVariable(variableName)) === null || _b === void 0 ? void 0 : _b.source_component_id;
    if (!sourceComponentId) {
        return [];
    }
    const impactedComponents = chain(childComponentIds)
        .map((childComponentId) => {
        const childComponent = builtComponentStore.getBuiltComponent(childComponentId);
        const childComponentVariable = childComponent === null || childComponent === void 0 ? void 0 : childComponent.findVariable(variableName);
        if ((childComponentVariable === null || childComponentVariable === void 0 ? void 0 : childComponentVariable.source_component_id) ===
            sourceComponentId) {
            return childComponentId;
        }
        else {
            return null;
        }
    })
        .compact()
        .value();
    return impactedComponents;
}, (componentId, variableName, componentGraphVersion) => `${componentId}-${variableName}-${componentGraphVersion}`);
export const useImpactedComponentIds = (componentId, variableName) => {
    const ids = ref(new Set());
    watch([componentId, variableName, globalComponentGraphVersion], () => {
        const componentIdValue = toValue(componentId);
        const variableNameValue = toValue(variableName);
        const globalComponentGraphVersionValue = toValue(globalComponentGraphVersion);
        if (!componentIdValue || !variableNameValue) {
            return;
        }
        ids.value = new Set(calculateImpactedComponentIds(componentIdValue, variableNameValue, globalComponentGraphVersionValue));
    }, {
        immediate: true,
    });
    return ids;
};
const useImpactedComponents = (componentId, variableName) => {
    const impactedComponentIds = useImpactedComponentIds(componentId, variableName);
    const impactedComponents = computed(() => {
        return chain(Array.from(impactedComponentIds.value))
            .map((impactedComponentId) => {
            return useBuiltComponent(impactedComponentId).builtComponent
                .value;
        })
            .compact()
            .value();
    });
    return { impactedComponents };
};
export const useImpactComponentVariableImpactSummary = (componentId, variableName) => {
    const { impactedComponents } = useImpactedComponents(componentId, variableName);
    const impactSummary = computed(() => {
        const campaignIds = new Set();
        const nonRootComponentIds = new Set();
        forEach(impactedComponents.value, (component) => {
            campaignIds.add(component.campaign_id);
            if (!component.isRoot()) {
                nonRootComponentIds.add(component.id);
            }
        });
        const impactedCampaignDescription = (() => {
            if (campaignIds.size === 0) {
                return "";
            }
            if (campaignIds.size === 1) {
                return "1 Campaign, ";
            }
            return `${campaignIds.size} Campaigns, `;
        })();
        const impactedCreativeDescription = (() => {
            if (nonRootComponentIds.size === 0) {
                return "";
            }
            if (nonRootComponentIds.size === 1) {
                return "1 Creative";
            }
            return `${nonRootComponentIds.size} Creatives`;
        })();
        const description = `${impactedCampaignDescription}${impactedCreativeDescription}`;
        return {
            description,
            impactedCreativeCount: nonRootComponentIds.size,
            impactedCampaignCount: campaignIds.size,
        };
    });
    return impactSummary;
};
export const pruneTree = (tree, nodesToKeep) => {
    const prune = (node, idsToKeep) => {
        return {
            componentId: node.componentId,
            children: chain(node.children)
                .map((childNode) => {
                const pruned = prune(childNode, idsToKeep);
                if (!idsToKeep.has(pruned.componentId) &&
                    pruned.children.length === 0) {
                    return null;
                }
                return pruned;
            })
                .compact()
                .value(),
        };
    };
    return computed(() => {
        const treeValue = toValue(tree);
        const nodesToKeepValue = toValue(nodesToKeep);
        return prune(treeValue, nodesToKeepValue);
    });
};
export const useChildComponentTree = (componentId) => {
    const componentGraphStore = useComponentGraphStore();
    return computed(() => {
        return componentGraphStore.getChildTree(componentId);
    });
};
export const useRootBuiltComponents = () => {
    const componentGraphStore = useComponentGraphStore();
    const rootComponentIds = computed(() => {
        return componentGraphStore.getRootComponentIds();
    });
    const builtComponentStore = useBuiltComponentStore();
    const { primer } = useBuiltComponents();
    const rootBuiltComponents = computed(() => {
        return chain(rootComponentIds.value)
            .map((rootComponentId) => {
            return builtComponentStore.getBuiltComponent(rootComponentId);
        })
            .compact()
            .value();
    });
    return {
        rootBuiltComponents,
        primer,
    };
};
export const useDirectChildBuiltComponents = (componentId) => {
    const directChildComponentIds = useChildComponentIds(componentId, ref(1));
    const builtComponentStore = useBuiltComponentStore();
    const directChildBuiltComponents = computed(() => {
        return chain(directChildComponentIds.value)
            .map((directChildComponentId) => {
            return builtComponentStore.getBuiltComponent(directChildComponentId);
        })
            .compact()
            .value();
    });
    return { directChildBuiltComponents };
};
export const useParentBuiltComponent = (componentId, variableName) => {
    const parentComponents = useParentBuiltComponents(componentId);
    const parentBuiltComponent = computed(() => {
        for (const parentComponent of parentComponents.value) {
            const variable = parentComponent.findVariable(toValue(variableName));
            if (variable &&
                variable.source_component_id === variable.context.componentId) {
                return parentComponent;
            }
        }
        return undefined;
    });
    return { parentBuiltComponent };
};
export const useComponentVariableNodeContext = (componentId, variableName) => {
    const { parentBuiltComponent } = useParentBuiltComponent(componentId, variableName);
    const impactedComponentIds = useImpactedComponentIds(componentId, variableName);
    const nodeType = computed(() => {
        const hasParentToInheritFrom = computed(() => {
            return parentBuiltComponent.value !== undefined;
        });
        const hasChildrenToOverride = computed(() => {
            return impactedComponentIds.value.size > 0;
        });
        if (hasParentToInheritFrom.value) {
            if (hasChildrenToOverride.value) {
                return "MEDIATOR";
            }
            else {
                return "INSTANCE";
            }
        }
        else {
            if (hasChildrenToOverride.value) {
                return "ASSET";
            }
            else {
                return "INDEPENDENT";
            }
        }
    });
    return {
        nodeType,
        parentBuiltComponent,
    };
};
