import { computed, toValue } from "vue";
import { useCampaignBuiltComponents } from "./useCampaignBuiltComponents";
export const useCampaignRootBuiltComponent = (campaignId) => {
    const { builtComponents, primer } = useCampaignBuiltComponents(campaignId);
    const campaignRootBuiltComponent = computed(() => {
        const campaignIdValue = toValue(campaignId);
        return builtComponents.value.find((component) => {
            return ((component === null || component === void 0 ? void 0 : component.campaign_id) === campaignIdValue &&
                (component === null || component === void 0 ? void 0 : component.name) === "root");
        });
    });
    return {
        campaignRootBuiltComponent,
        primer,
    };
};
