import app from "src/shared/app";
import Session from "js/Session";
import extoleInvite from "src/shared/third-party-integrations/extole-invite";
import queryStringService from "src/shared/query-string-service";
import "scss/errors/error-page.scss";
import faro from "./shared/third-party-integrations/faro";
import { createApp } from "vue";
import Error404Page from "./shared/v-components/Error404Page.vue";
import Page from "src/account-rewards/v-components/Page.vue";
import { createPinia } from "pinia";
import navigation from "src/shared/navigation/app";
import $ from "jquery";
import router from "src/account-rewards/v-components/router";
import "vendor/foundation/css/foundation.css";
import "vendor/fontawesome/css/fontawesome-all.min.css";
import "vendor/fontawesome-v6/css/custom-icons.min.css";
import "scss/extole-default-styles.scss";
app.onStart(() => {
    const path = document.location.pathname;
    const isUserSupportUser = Session.getInstance().isUserSupportUser();
    if (path === "/") {
        document.location = isUserSupportUser
            ? "/profiles/search"
            : "/programs";
    }
    else if (document.location.href.includes("/notifications/view?")) {
        const userId = queryStringService.getParameter("user_id");
        const eventId = queryStringService.getParameter("event_id");
        window.location.href = `/notifications/?user_id=${userId}#/view/${eventId}`;
    }
    else if (path.includes("reports-v2")) {
        window.location.href = path.replace("/reports-v2", "/reports");
    }
    else if (path.includes("/account-rewards")) {
        navigation.start();
        $(".header-side__wrapper").after("<div id='app' class='page-wrapper'></div>");
        const displayType = extractStringAfterAccountRewards(path);
        if (displayType) {
            createApp(Page, {
                displayType,
            })
                .use(createPinia())
                .use(router)
                .mount("#app");
            return;
        }
        else {
            document.location = "/account-rewards";
        }
    }
    else {
        extoleInvite.bootstrap();
        faro.pushError(new Error(`PAGE_NOT_FOUND: ${location.pathname}`));
        createApp(Error404Page).mount(document.body);
    }
});
const extractStringAfterAccountRewards = (string) => {
    const regex = /\/account-rewards\/([^\/?#]+)/;
    const match = string.match(regex);
    if (match && match[1]) {
        return match[1];
    }
    else {
        return null;
    }
};
app.start();
