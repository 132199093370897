import Backbone from "backbone";
import _ from "underscore";
import $ from "jquery";
import RewardSupplierDisplayTypesCollection from "./RewardSupplierDisplayTypesCollection";
import featureFlagService from "src/shared/feature-flag-service";

const BuiltRewardSupplierCollection = Backbone.Collection.extend({
    url: "/api/v6/reward-suppliers/built",

    initialize() {
        this.rewardSupplierDisplayTypesCollection =
            RewardSupplierDisplayTypesCollection.create();
    },

    fetch(options) {
        const deferred = $.Deferred();
        $.when(
            this.rewardSupplierDisplayTypesCollection.fetch(),
            Backbone.Collection.prototype.fetch.call(this, options)
        )
            .done(() => {
                deferred.resolve(this);
            })
            .fail(deferred.reject);
        return deferred;
    },

    parse(response) {
        const areRewardSupplierDisplayTypesContainShopify =
            this.rewardSupplierDisplayTypesCollection.some((model) =>
                model.get("value")?.toLowerCase().includes("shopify")
            );
        return response.filter((model) => {
            if (
                areRewardSupplierDisplayTypesContainShopify &&
                featureFlagService.isOn(
                    featureFlagService.FLAGS.ENFORCE_SHOPIFY_RESTRICTIONS
                )
            ) {
                return model.display_type.toLowerCase().includes("shopify");
            }
            return true;
        });
    },
});

BuiltRewardSupplierCollection.create = function () {
    return new BuiltRewardSupplierCollection();
};

export const loadRewardSuppliers = _.memoize(() => {
    const deferred = $.Deferred();
    const rewardSuppliers = BuiltRewardSupplierCollection.create();
    $.when(rewardSuppliers.fetch())
        .done(() => {
            deferred.resolve(rewardSuppliers);
        })
        .fail(deferred.reject);
    return deferred;
});

export default BuiltRewardSupplierCollection;
