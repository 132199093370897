var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import CampaignEndpoints from "src/shared/api/campaign/CampaignEndpoints";
import { toPromise } from "src/shared/api/promise";
import _ from "underscore";
import { reactive } from "vue";
import { campaignArchiveEvent, campaignChangeEvent, } from "../event-bus/campaign";
import { campaignComponentChangeEvent } from "../event-bus/campaign-component";
import { campaignModuleEventBus, EVENT } from "../modules/campaign/event-bus";
import { BuiltCampaign } from "./model/BuiltCampaign";
export const useBuiltCampaignStore = defineStore("builtCampaignStore", () => {
    const state = reactive({
        builtCampaigns: {},
    });
    const setBuiltCampaigns = (builtCampaignResponses) => {
        return _.map(builtCampaignResponses, (response) => {
            const builtCampaign = new BuiltCampaign(response);
            state.builtCampaigns[builtCampaign.campaign_id] = builtCampaign;
            return builtCampaign;
        });
    };
    const fetchBuiltCampaigns = () => __awaiter(void 0, void 0, void 0, function* () {
        setBuiltCampaigns(yield toPromise(CampaignEndpoints.getBuiltCampaigns()));
    });
    const fetchBuiltCampaign = (campaignId) => __awaiter(void 0, void 0, void 0, function* () {
        const [builtCampaign] = setBuiltCampaigns([
            yield toPromise(CampaignEndpoints.getBuiltCampaign(campaignId)),
        ]);
        return builtCampaign;
    });
    const getBuiltCampaigns = () => {
        return _.values(state.builtCampaigns);
    };
    const getBuiltCampaign = (campaignId) => {
        return state.builtCampaigns[campaignId];
    };
    const listIncentiveTransitionRules = (campaignId) => {
        var _a;
        const campaign = getBuiltCampaign(campaignId);
        return ((_a = campaign === null || campaign === void 0 ? void 0 : campaign.incentive) === null || _a === void 0 ? void 0 : _a.transition_rules) || [];
    };
    const listFlowSteps = (campaignId, { flowPath }) => {
        const campaign = getBuiltCampaign(campaignId);
        let flowSteps = (campaign === null || campaign === void 0 ? void 0 : campaign.flow_steps) || [];
        if (flowPath) {
            flowSteps = _.where(flowSteps, {
                flow_path: flowPath,
            });
        }
        return flowSteps;
    };
    campaignModuleEventBus.subscribe(EVENT.CAMPAIGN_INVALIDATED, (payload) => {
        fetchBuiltCampaign(payload.campaignId);
    });
    campaignComponentChangeEvent.on((event) => {
        fetchBuiltCampaign(event.campaignId);
    });
    campaignChangeEvent.on((event) => {
        fetchBuiltCampaign(event.campaignId);
    });
    campaignArchiveEvent.on((event) => {
        delete state.builtCampaigns[event.campaignId];
    });
    return {
        state,
        fetchBuiltCampaigns,
        fetchBuiltCampaign,
        getBuiltCampaigns,
        getBuiltCampaign,
        listIncentiveTransitionRules,
        listFlowSteps,
    };
});
