import { computed } from "vue";
import { useBuiltComponent } from "./useBuiltComponent";
import { useGlobalCampaignRootBuiltComponent } from "./useGlobalCampaignRootBuiltComponent";
import { useCampaignSummary } from "../../campaign-summary/composables";
import Strings from "js/Strings";
export const useComponentDisplayName = (componentId) => {
    const { builtComponent } = useBuiltComponent(componentId);
    const { globalCampaignRootBuiltComponent } = useGlobalCampaignRootBuiltComponent();
    const displayName = computed(() => {
        var _a, _b;
        if (!builtComponent.value) {
            return undefined;
        }
        if (((_a = globalCampaignRootBuiltComponent.value) === null || _a === void 0 ? void 0 : _a.id) ===
            builtComponent.value.id) {
            return "My Account";
        }
        if (builtComponent.value.name === "root") {
            return (_b = useCampaignSummary(builtComponent.value.campaign_id)
                .campaignSummary.value) === null || _b === void 0 ? void 0 : _b.name;
        }
        return Strings.capitalize(builtComponent.value.name);
    });
    return {
        displayName,
    };
};
