import { isEqual } from "lodash";
import { computed, reactive, toValue } from "vue";
const componentVariableMutableFilters = reactive({});
const defaultFilter = {
    search: "",
    showOnlyUsingDefaultValue: false,
    showOnlyNotUsingDefaultValue: false,
    showOnlyTranslatable: false,
    showOnlyEssential: false,
    primaryCategory: null,
};
const getOrCreateFilter = (componentMutableKey) => {
    if (!componentVariableMutableFilters[componentMutableKey]) {
        componentVariableMutableFilters[componentMutableKey] =
            reactive(Object.assign({}, defaultFilter));
    }
    return componentVariableMutableFilters[componentMutableKey];
};
export const useComponentVariableMutableFilter = (componentMutableKey) => {
    const componentVariableMutableFilter = computed(() => {
        const componentMutableKeyValue = toValue(componentMutableKey);
        return getOrCreateFilter(componentMutableKeyValue);
    });
    return { componentVariableMutableFilter };
};
export const isFilteringComponentVariables = (componentMutableKey) => {
    return !isEqual(componentVariableMutableFilters[componentMutableKey], defaultFilter);
};
export const isTextSearchingComponentVariables = (componentMutableKey) => {
    const filter = getOrCreateFilter(componentMutableKey);
    return filter.search !== "";
};
export const toggleQuickstart = (componentMutableKey) => {
    const filter = getOrCreateFilter(componentMutableKey);
    if (filter.showOnlyEssential) {
        filter.showOnlyEssential = false;
    }
    else {
        filter.showOnlyEssential = true;
        filter.primaryCategory = null;
    }
};
export const selectPrimaryCategory = (componentMutableKey, primaryCategory) => {
    const filter = getOrCreateFilter(componentMutableKey);
    filter.primaryCategory = primaryCategory;
    filter.showOnlyEssential = false;
};
export const togglePrimaryCategory = (componentMutableKey, primaryCategory) => {
    const filter = getOrCreateFilter(componentMutableKey);
    if (filter.primaryCategory === primaryCategory) {
        filter.primaryCategory = null;
    }
    else {
        filter.primaryCategory = primaryCategory;
    }
    filter.showOnlyEssential = false;
};
