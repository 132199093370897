export const calculateOffset = (parameters) => {
    return (parameters.currentPageNumber - 1) * parameters.limit;
};
export const isAtStart = (currentPageNumber) => {
    return currentPageNumber === 1;
};
export const isAtEnd = (results, limit) => {
    return results.length < limit;
};
export const calculateNextPageNumber = (currentPageNumber) => {
    return currentPageNumber + 1;
};
export const getPage = (items, parameters) => {
    const offset = calculateOffset(parameters);
    return items.slice(offset, offset + parameters.limit);
};
export const calculatePreviousPageNumber = (currentPageNumber) => {
    if (currentPageNumber > 1) {
        return currentPageNumber - 1;
    }
    return currentPageNumber;
};
