import Session from "js/Session";
import { computed } from "vue";
import { useDeveloperMode } from "../../me/composables";
import { useComponent } from "./useComponent";
export const filterComponentVariables = (variables) => {
    const { developerMode } = useDeveloperMode();
    const isSuperUser = Session.getInstance().isSuperUser();
    if (developerMode.value) {
        return variables;
    }
    const variablesExcludingUIDisplayOnly = variables.filter((variable) => {
        return !variable.isUIDisplayOnly();
    });
    if (isSuperUser) {
        return variablesExcludingUIDisplayOnly;
    }
    const variablesExcludingExpertImportance = variablesExcludingUIDisplayOnly.filter((variable) => {
        return variable.getImportance() !== "EXPERT";
    });
    return variablesExcludingExpertImportance;
};
export const useComponentVariables = (componentId) => {
    const { component, primer } = useComponent(componentId);
    const componentVariables = computed(() => {
        var _a, _b;
        return filterComponentVariables((_b = (_a = component.value) === null || _a === void 0 ? void 0 : _a.variables) !== null && _b !== void 0 ? _b : []);
    });
    return {
        primer,
        componentVariables,
    };
};
