var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { computed, toValue } from "vue";
import { useComponentStoreV2 } from "..";
import { AsyncPrimer } from "../../common/async-primer";
const asyncPrimer = new AsyncPrimer((key) => key.componentId, ({ componentId }) => __awaiter(void 0, void 0, void 0, function* () {
    const componentStore = useComponentStoreV2();
    yield componentStore.fetchComponent(componentId);
}));
export const useComponent = (componentId) => {
    const componentStore = useComponentStoreV2();
    const primer = asyncPrimer.prime(() => {
        const componentIdValue = toValue(componentId);
        if (!componentIdValue) {
            return undefined;
        }
        return { componentId: componentIdValue };
    });
    const component = computed(() => {
        const componentIdValue = toValue(componentId);
        if (!componentIdValue) {
            return undefined;
        }
        return componentStore.getComponent(componentIdValue);
    });
    return {
        primer,
        component,
    };
};
