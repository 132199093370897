var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { computed, reactive, ref, watchEffect } from "vue";
import { getPage, } from "../../common/pagination";
import { useRewardSupplierStore } from "./index";
import { useAsyncPrimer, useCompositeAsyncPrimer, } from "../../common/async-primer";
import { useCampaignRewardRule } from "../../campaign/incentive/reward/rule/composables";
import { useBuiltComponents, useBuiltComponentVariableValue, } from "../../component-built/composables";
import RewardSupplierWorkflow from "src/account-rewards/workflow/RewardSupplierWorkflow";
import { sortBy } from "lodash";
import { campaignChangeEvent } from "../../event-bus/campaign";
import featureFlagService from "src/shared/store/feature-flag/feature-flag-service";
const REWARD_SUPPLIER_TYPES = {
    CUSTOM_REWARD: {
        link: "/account-rewards/custom-rewards/view#{id}",
    },
    TANGO_V2: {
        link: "/account-rewards/gift-cards/view#{id}",
    },
    MANUAL_COUPON: {
        link: "/account-rewards/coupons/view#{id}",
    },
    PAYPAL_PAYOUTS: {
        link: "/account-rewards/paypal-payouts/view#{id}",
    },
    SALESFORCE_COUPON: {
        link: "/account-rewards/SFCC/view#{id}",
    },
};
const lookup = (type) => {
    return REWARD_SUPPLIER_TYPES[type] || {};
};
const getDetailPageLink = (rewardSupplier) => {
    const detailLinkTemplate = lookup(rewardSupplier === null || rewardSupplier === void 0 ? void 0 : rewardSupplier.reward_supplier_type).link;
    if (detailLinkTemplate) {
        return detailLinkTemplate.replace("{id}", rewardSupplier === null || rewardSupplier === void 0 ? void 0 : rewardSupplier.id);
    }
    return undefined;
};
export const useRewardSupplierResultSet = (parameters, eventBus) => {
    const rewardSupplierStore = useRewardSupplierStore();
    const resultSet = reactive({
        results: [],
        isLoading: false,
    });
    const fetchRewardSuppliers = () => __awaiter(void 0, void 0, void 0, function* () {
        yield rewardSupplierStore.fetchRewardSupplierDisplayTypes();
        yield rewardSupplierStore.fetchBuiltRewardSuppliers(parameters.value);
    });
    const updateResults = () => {
        resultSet.isLoading = true;
        const rewardSupplierDisplayTypes = rewardSupplierStore.listAllRewardSupplierDisplayTypes();
        const areRewardSupplierDisplayTypesContainShopify = rewardSupplierDisplayTypes.some((displayType) => displayType.toLowerCase().includes("shopify"));
        const rewardSuppliers = sortBy(rewardSupplierStore.listAllRewardSuppliers(), (rewardSupplier) => {
            return new Date(rewardSupplier.updated_date).getTime() * -1;
        });
        const filteredRewardSuppliers = rewardSuppliers.filter((rewardSupplier) => {
            if (areRewardSupplierDisplayTypesContainShopify &&
                featureFlagService.isOn("ENFORCE_SHOPIFY_RESTRICTIONS")) {
                return rewardSupplier.display_type
                    .toLowerCase()
                    .includes("shopify");
            }
            return true;
        });
        resultSet.results = getPage(filteredRewardSuppliers, parameters.value);
        resultSet.isLoading = false;
    };
    eventBus.on(RewardSupplierWorkflow.EVENTS.COMPLETE, fetchRewardSuppliers);
    eventBus.on(RewardSupplierWorkflow.EVENTS.REMOVED, fetchRewardSuppliers);
    fetchRewardSuppliers();
    watchEffect(updateResults);
    return resultSet;
};
export const useBuiltRewardSuppliers = () => {
    const store = useRewardSupplierStore();
    const { areRewardSupplierDisplayTypesContainShopify } = useAreRewardSupplierDisplayTypesContainShopify();
    const primer = useAsyncPrimer(() => __awaiter(void 0, void 0, void 0, function* () {
        yield store.fetchBuiltRewardSuppliers({
            include_archived: false,
            include_disabled: true,
        });
    }), "useRewardSuppliers");
    const rewardSuppliers = computed(() => {
        if (areRewardSupplierDisplayTypesContainShopify.value &&
            featureFlagService.isOn("ENFORCE_SHOPIFY_RESTRICTIONS")) {
            return store.listAllRewardSuppliers().filter((rewardSupplier) => {
                return rewardSupplier.display_type
                    .toLowerCase()
                    .includes("shopify");
            });
        }
        return store.listAllRewardSuppliers();
    });
    return {
        primer,
        rewardSuppliers,
    };
};
export const useRewardSupplierByDisplayType = (displayType) => {
    const { rewardSuppliers, primer } = useBuiltRewardSuppliers();
    const rewardSupplier = computed(() => {
        return rewardSuppliers.value.find((rewardSupplier) => {
            return rewardSupplier.display_type === displayType;
        });
    });
    return {
        primer,
        rewardSupplier,
    };
};
export const useRewardSupplier = (rewardSupplierId) => {
    const store = useRewardSupplierStore();
    const primer = useAsyncPrimer(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!store.getRewardSupplier(rewardSupplierId)) {
            yield store.fetchBuiltRewardSuppliers({ include_archived: true });
        }
    }), `useRewardSupplier.${rewardSupplierId}`);
    const rewardSupplier = computed(() => {
        return store.getRewardSupplier(rewardSupplierId);
    });
    campaignChangeEvent.on(() => {
        store.fetchBuiltRewardSuppliers({ include_archived: true });
    });
    return {
        primer,
        rewardSupplier,
    };
};
export const useRewardSuppliers = () => {
    const store = useRewardSupplierStore();
    const primer = useAsyncPrimer(() => __awaiter(void 0, void 0, void 0, function* () {
        yield store.fetchRewardSuppliers({
            include_archived: true,
            include_disabled: true,
        });
    }), "useRewardSuppliersList");
    const rewardSuppliers = computed(() => {
        return store.listAllRewardSuppliers();
    });
    return {
        primer,
        rewardSuppliers,
    };
};
export const useRewardSupplierLink = (rewardSupplierId) => {
    const { rewardSupplier, primer } = useRewardSupplier(rewardSupplierId);
    const link = computed(() => {
        return getDetailPageLink(rewardSupplier.value);
    });
    return {
        primer,
        link,
    };
};
export const useRewardSupplierByRewardRuleId = (campaignId, ruleId) => {
    var _a;
    const { primer: rewardRulePrimer, campaignRewardRule } = useCampaignRewardRule(campaignId, ruleId);
    const { rewardSupplier, primer: rewardPrimer } = useRewardSupplier((_a = campaignRewardRule.value) === null || _a === void 0 ? void 0 : _a.reward_supplier_id);
    const primer = useCompositeAsyncPrimer(rewardRulePrimer, rewardPrimer);
    return {
        primer,
        rewardSupplier,
    };
};
export const useRewardSupplierDisplayTypes = () => {
    const store = useRewardSupplierStore();
    const primer = useAsyncPrimer(() => __awaiter(void 0, void 0, void 0, function* () {
        yield store.fetchRewardSupplierDisplayTypes();
    }), "useRewardSupplierDisplayTypes");
    const displayTypes = computed(() => {
        return store.listAllRewardSupplierDisplayTypes();
    });
    return {
        primer,
        displayTypes,
    };
};
export const useAreRewardSupplierDisplayTypesContainShopify = () => {
    const { displayTypes, primer } = useRewardSupplierDisplayTypes();
    const areRewardSupplierDisplayTypesContainShopify = computed(() => {
        return displayTypes.value.some((displayType) => displayType.toLowerCase().includes("shopify"));
    });
    return {
        primer,
        areRewardSupplierDisplayTypesContainShopify,
    };
};
export const useComponentRewardSuppliers = () => {
    const { displayTypes, primer: displayTypePrimer } = useRewardSupplierDisplayTypes();
    const { builtComponents, primer: componentsPrimer } = useBuiltComponents({
        havingAllTags: ["internal:reward-supplier"],
        owner: "CLIENT",
    });
    const primer = useCompositeAsyncPrimer(displayTypePrimer, componentsPrimer);
    const componentRewardSuppliers = computed(() => {
        if (displayTypes.value.length === 0) {
            return [];
        }
        return displayTypes.value
            .map((displayType) => {
            var _a;
            const { rewardSupplier } = useRewardSupplierByDisplayType(displayType);
            const componentNameFromRewardSuppliersTag = (_a = rewardSupplier.value) === null || _a === void 0 ? void 0 : _a.getIntegrationNameFromTags();
            const integration = builtComponents.value.find((component) => component.name === componentNameFromRewardSuppliersTag);
            if (integration) {
                return {
                    id: integration.id,
                    type: useBuiltComponentVariableValue(ref(integration.id), ref("name"), ref("default")).value,
                    display_name: displayType,
                    description: useBuiltComponentVariableValue(ref(integration.id), ref("short.description"), ref("default")).value,
                    image: useBuiltComponentVariableValue(ref(integration.id), ref("rewardSupplierLogo"), ref("default")).value,
                    isVisible: true,
                };
            }
            else {
                return null;
            }
        })
            .filter((integration) => integration !== null);
    });
    return {
        primer,
        componentRewardSuppliers,
    };
};
