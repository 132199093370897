import _ from "underscore";
import { has } from "lodash";
import { computed } from "vue";
import { useCampaignRootBuiltComponent } from "../component-built/composables";
export const DEFAULT_VARIANT = "default";
export const EN_VARIANT = "en";
export const useCampaignVariantsBuiltComponentVariable = (campaignId) => {
    const { campaignRootBuiltComponent } = useCampaignRootBuiltComponent(campaignId);
    const variantsVariable = computed(() => {
        var _a;
        return (_a = campaignRootBuiltComponent.value) === null || _a === void 0 ? void 0 : _a.findVariable("variants");
    });
    return {
        variantsVariable,
    };
};
export const normalizePreferredVariant = (preferredVariant) => {
    if (preferredVariant === null) {
        return EN_VARIANT;
    }
    return preferredVariant;
};
export const calculateDefaultVariant = (variants) => {
    if (has(variants, EN_VARIANT)) {
        return EN_VARIANT;
    }
    return DEFAULT_VARIANT;
};
export const calculateWorkingVariant = (preferredVariant, values) => {
    if (has(values, preferredVariant)) {
        return preferredVariant;
    }
    return calculateDefaultVariant(values);
};
export const calculateVariantsFromLegacyCreativeVariableValues = (updates) => {
    const unifiedValues = [];
    if (updates.defaultLocale) {
        const defaultValue = _.values(updates.defaultLocale.values);
        const defaultValueArray = _.flatten(defaultValue.map((value) => {
            return value.split(",").map((item) => item.trim());
        }));
        unifiedValues.push(...defaultValueArray);
    }
    if (updates.enabledLocales) {
        const enabledValues = _.values(updates.enabledLocales.values);
        const enabledValuesArray = _.flatten(enabledValues.map((value) => {
            return value.split(",").map((item) => item.trim());
        }));
        unifiedValues.push(...enabledValuesArray);
    }
    return _.uniq(unifiedValues);
};
