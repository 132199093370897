import { has } from "lodash";
export const getErrorMessage = (error, includeParameters = false) => {
    if (has(error, "responseJSON")) {
        return getErrorMessage(error.responseJSON, includeParameters);
    }
    if (has(error, "message")) {
        return (error.message +
            (includeParameters && has(error, "parameters")
                ? " " +
                    Object.keys(error.parameters)
                        .map((key) => `${key}: ${error.parameters[key]}`)
                        .join(", ")
                : ""));
    }
    return "Unexpected error";
};
class Exception {
    constructor(code, message) {
        this.code = code;
        this.message = message;
    }
    static fromExtoleRestException(extoleRestException) {
        return new Exception(extoleRestException.responseJSON.code, this.decodeHtmlEntity(extoleRestException.responseJSON.message));
    }
}
Exception.decodeHtmlEntity = (str) => {
    return str.replace(/&#(\d+);/g, (match, dec) => {
        return String.fromCharCode(dec);
    });
};
Exception.UNEXPECTED_ERROR = new Exception("unexpected_error", "Something unexpected happened");
export default Exception;
